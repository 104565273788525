import { NetStatusService } from 'services/net-status-service';
import { DialogCloseResult, DialogController, DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { CustomerService } from 'services/customer-service';
import { ErrorService } from 'services/error-service';
import { ServiceStatusService } from 'services/service-status-service';
import { ServiceStationService } from 'services/service-station-service';
import { ServicePriorityService } from 'services/service-priority-service';
import { SiteService } from 'services/site-service';
import { ServiceStationStorageService } from 'services/service-station-storage-service';
import { NetTypeService } from 'services/net-type-service';
import { NetShapeService } from 'services/net-shape-service';
import * as moment from 'moment';
import { ServiceService } from 'services/service-service';
import { UserService } from 'services/user-service';
import { UserModels } from 'models/UserModels';
import { ProducerService } from 'services/producer-service';
import { SpecialProductTypeService } from 'services/special-product-type-service';
import { DesignTypeService } from 'services/design-type-service';
import { FloaterTypeService } from 'services/floater-type-service';

@autoinject
export class AnalysisFilters {
  @bindable private analysisFilters: any;
  @bindable private getQuery;
  @bindable private context;
  @bindable private fields;
  @bindable private pageSize;
  @bindable private getAnalyses;
  @bindable private toggleVisible;
  @bindable private getKey;
  @bindable private isNetList;
  @bindable private customerId;
  @bindable private clearAll;

  private analysisFilterTypes = {
    CHECKBOX: 'checkbox',
    RADIO: 'radio',
    RADIO_SIMPLE: 'radioSimple',
    RANGE: 'range',
    RANGE_DATE: 'rangeDate',
  };

  private analysisFilterInputInFocus: object = {};
  private analysisFilterDatepickerInFocus: any;

  constructor(
    private serviceService: ServiceService,
    private customerService: CustomerService,
    private errorService: ErrorService,
    private netStatusService: NetStatusService,
    private eventAggregator: EventAggregator,
    private serviceStationService: ServiceStationService,
    private servicePriorityService: ServicePriorityService,
    private floaterTypeService: FloaterTypeService,
    private siteService: SiteService,
    private designTypeService: DesignTypeService,
    private serviceStationStorageService: ServiceStationStorageService,
    private netTypeService: NetTypeService,
    private netShapeService: NetShapeService,
    private i18n: I18N,
    private userService: UserService,
    private producerService: ProducerService,
    private specialProductTypeService: SpecialProductTypeService
  ) {}

  private attached() {
    this.getQuery = this.analysisFilterGetFiltersQuery.bind(this);
    this.toggleVisible = this.analysisFilterToggleVisible.bind(this);
    this.clearAll = this.clearAllAnalysisFilters.bind(this);
  }

  // Helper-function for netFilters
  private analysisFilterValuesToArray(analysisFilter) {
    if (!analysisFilter?.values || Object.keys(analysisFilter.values)?.length === 0) {
      return null;
    }

    const values = Object.entries(analysisFilter?.values);
    const data = values?.map((it) => {
      const item = {
        key: it[0],
        value: it[1],
        object: null,
      };

      const objectRef = analysisFilter.options.find((it) => it?.Id == item.key);
      if (objectRef) {
        item.object = objectRef;
      }

      return item;
    });

    return data;
  }

  private i18nName(row) {
    if (!row) {
      return null;
    }

    const lang = this.i18n.getLocale();
    switch (lang) {
      case 'nb-NO':
        return row['Name'];
      case 'en':
        return row['NameEn'] ? row['NameEn'] : row['Name'];
      case 'es':
        return row['NameEs'] ? row['NameEs'] : row['NameEn'];
      default:
        return row['Name'];
    }
  }

  // Sets the selected-label that shows in the dropdown
  // Converts array to compact string etc.
  private async setAnalysisFilterValueLabel(analysisFilter) {
    // Checkbox
    if (analysisFilter.type === this.analysisFilterTypes.CHECKBOX) {
      const values: any = this.analysisFilterValuesToArray(analysisFilter)
        ?.filter((it) => it.value == true)
        ?.sort((a, b) => {
          if (a?.object?.SortIndex && b?.object?.SortIndex) {
            return a.object.SortIndex > b.object.SortIndex ? 1 : -1;
          } else {
            return 0;
          }
        });

      const fullString = values?.map((it: any) => this.i18nName(it.object)).join(', ');

      if (!values || values.length == 0) {
        analysisFilter._valueLabel = this.i18n.tr('servicefilters.noneSelected');
        analysisFilter._valueIsNone = true;
      } else if (values.length == 1) {
        analysisFilter._valueLabel = fullString.substring(0, 16);
        analysisFilter._valueIsNone = false;
      } else if (values.length > 1 && fullString.length > 16) {
        analysisFilter._valueLabel = `${fullString.substring(0, 16)} ...`;
        analysisFilter._valueIsNone = false;
      } else {
        analysisFilter._valueLabel = fullString.substring(0, 16);
        analysisFilter._ValueLabel = false;
      }
    }

    // Range
    if (analysisFilter.type === this.analysisFilterTypes.RANGE) {
      if (analysisFilter.values.from && analysisFilter.values.to) {
        analysisFilter._valueLabel = `${analysisFilter.values.from} - ${analysisFilter.values.to}`;
      } else if (analysisFilter.values.from) {
        analysisFilter._valueLabel = `${this.i18n.tr('servicefilters.rangeFrom').toLowerCase()} ${
          analysisFilter.values.from
        }`;
      } else if (analysisFilter.values.to) {
        analysisFilter._valueLabel = `${this.i18n.tr('servicefilters.rangeTo').toLowerCase()} ${
          analysisFilter.values.to
        }`;
      } else {
        analysisFilter._valueLabel = this.i18n.tr('servicefilters.noneSelected');
      }
    }

    // Range Date
    if (analysisFilter.type === this.analysisFilterTypes.RANGE_DATE) {
      if (analysisFilter.values.from && analysisFilter.values.to) {
        analysisFilter._valueLabel = `${moment.utc(analysisFilter.values.from).format('DD.MM.YYYY')} - ${moment
          .utc(analysisFilter.values.to)
          .format('DD.MM.YYYY')}`;
      } else if (analysisFilter.values.from) {
        analysisFilter._valueLabel = `${this.i18n.tr('servicefilters.rangeFrom').toLowerCase()} ${moment
          .utc(analysisFilter.values.from)
          .format('DD.MM.YYYY')}`;
      } else if (analysisFilter.values.to) {
        analysisFilter._valueLabel = `${this.i18n.tr('servicefilters.rangeTo').toLowerCase()} ${moment
          .utc(analysisFilter.values.to)
          .format('DD.MM.YYYY')}`;
      } else {
        analysisFilter._valueLabel = this.i18n.tr('servicefilters.noneSelected');
      }
    }

    // Radio simple
    if (analysisFilter.type === this.analysisFilterTypes.RADIO_SIMPLE) {
      const selected = analysisFilter.options.find(({ value }) => value === analysisFilter.values);
      analysisFilter._valueLabel = selected.label;
    }

    // Radio
    if (analysisFilter.type === this.analysisFilterTypes.RADIO) {
      setTimeout(() => {
        const selected = analysisFilter.options.find(({ Id }) => Id === analysisFilter.values);
        analysisFilter._valueLabel = this.i18nName(selected) ?? this.i18n.tr('servicefilters.noneSelected');
      });
    }
  }

  // Sets the _selectedOptions on applicable filters
  // This array is just a helper for the frontend-logic and repeaters
  private setAnalysisFilterSelectedOptions(analysFilter) {
    if (analysFilter.type === this.analysisFilterTypes.CHECKBOX) {
      const selected = analysFilter.options.filter((option) => {
        return analysFilter.values[option.Id];
      });

      analysFilter._selectedOptions = selected;
    }
  }

  private async setAnalysisFiltersDisabled() {
    for (const [key, analysisFilter] of Object.entries(this.analysisFilters) as any) {
      if (typeof analysisFilter === 'object') {
        if (analysisFilter.disabled !== undefined) {
          if (typeof analysisFilter.disabled === 'function') {
            this.analysisFilters[key]._disabled = analysisFilter.disabled(this.analysisFilters);
          } else {
            this.analysisFilters[key]._disabled = analysisFilter.disabled;
          }

          this.eventAggregator.publish('analysisFilterOptionsRefresh', key);
        }
      }
    }
  }

  private async analysisFilterGetFiltersQuery(fields = null): Promise<object> {
    return new Promise(async (resolve, reject) => {
      if (!this.analysisFilters) {
        await this.setupAnalysisFilters();
      }
      const query: object = {};

      const valuesToArray = (analysisFilters) => {
        if (analysisFilters.type === this.analysisFilterTypes.CHECKBOX) {
          return (
            Object.entries(analysisFilters?.values)
              .filter(([key, value]) => !!value)
              .map(([key]) => key) ?? []
          );
        } else {
          return analysisFilters?.values;
        }
      };

      const filters: Array<[string, any]> = Object.entries(this.analysisFilters);
      for (let [filterKey, filter] of filters) {
        // Has custom query
        if (filter?.query) {
          // Netfilter uses custom query-key
          if (typeof filter.query === 'string') {
            const values = valuesToArray(filter);
            if (values?.length > 0) {
              query[filter.query] = valuesToArray(filter);
            }
          }

          // Netfilter uses custom query-function
          else if (typeof filter.query === 'function') {
            // Multiple values i returned
            const values = filter.query(filter.values);
            if (Array.isArray(values)) {
              if (values.length > 0) {
                values.forEach(({ key, value }) => {
                  query[key] = value;
                });
              }
            }

            // Single value is returned
            else {
              if (values.value && values.value !== '') {
                query[values.key] = values.value;
              }
            }
          }
        }

        // Use default query-handling
        else if (filter?.type) {
          // Range
          if (filter.type === this.analysisFilterTypes.RANGE) {
            query[`${filter.name}From`] = filter.values.from;
            query[`${filter.name}To`] = filter.values.to;
          }

          // Range Date
          if (filter.type === this.analysisFilterTypes.RANGE_DATE) {
            query[`${filter.name}From`] = filter.values.from
              ? moment.utc(filter.values.from).format('YYYY-MM-DD')
              : null;
            query[`${filter.name}To`] = filter.values.to ? moment.utc(filter.values.to).format('YYYY-MM-DD') : null;
          }

          // Radio simple
          if (filter.type === this.analysisFilterTypes.RADIO_SIMPLE) {
            if (filter.values !== null) {
              query[filter.name] = filter.values;
            }
          }

          // Radio
          if (filter.type === this.analysisFilterTypes.RADIO) {
            query[filter.name] = filter.values;
          }
        }
      }

      // Misc
      query['_select'] = fields
        ? fields
            ?.filter((x) => x.selected && !x.function)
            .map((x) => x.field)
            .join(',')
        : this.fields
            ?.filter((x) => x.selected && !x.function)
            .map((x) => x.field)
            .join(',');
      query['_export'] = false;
      query['top'] = this.pageSize;

      return resolve(query);
    });
  }

  // Radio-buttons needs a middle-function because of some
  // binding-issues, and because the click-event has to
  // get a true in return
  private analysisFilterRadioChangeProxy(analysFilter, option, event) {
    setTimeout(() => {
      this.analysisFilterChange(analysFilter, option, event);
    });
    return true;
  }

  // Datepickers need a middle-function to better handle changes
  private analysisFilterDateChangeProxy(analysisFilter, option, event) {
    if (!event.firedBy) {
      return this.analysisFilterChange(analysisFilter, option, event);
    }

    const date = event.firedBy._d;
    if (
      date &&
      analysisFilter?.values[option] &&
      moment.default(date).format('YYYY-MM-DD') == moment.default(analysisFilter?.values[option]).format('YYYY-MM-DD')
    ) {
      return;
    }

    // hack because datepicker is slow
    setTimeout(() => {
      this.analysisFilterChange(analysisFilter, option, event);
    }, 500);
  }

  private async analysisFilterChange(analysisFilter, option, event) {
    // Filter has custom change-function
    if (analysisFilter.change) {
      analysisFilter.change(option, event, analysisFilter.values);
    }

    // Use default change-functions
    else {
      if (analysisFilter.type === this.analysisFilterTypes.CHECKBOX) {
        analysisFilter.values[option.Id] = event.target.checked;
        analysisFilter._clearable = Object.values(analysisFilter.values).includes(true);
      }

      if (analysisFilter.type === this.analysisFilterTypes.RANGE) {
        analysisFilter._clearable = analysisFilter.values.from || analysisFilter.values.to;
      }

      if (analysisFilter.type === this.analysisFilterTypes.RANGE_DATE) {
        analysisFilter.values[`${analysisFilter.name}${option}`] = event.detail;
        analysisFilter._clearable = analysisFilter.values.from || analysisFilter.values.to;
      }

      if (analysisFilter.type === this.analysisFilters.RADIO_SIMPLE) {
        this.analysisFilters[analysisFilter.name].values = option.value;
        analysisFilter._clearable = analysisFilter.values !== null;
      }

      if (analysisFilter.type === this.analysisFilters.RADIO) {
        this.analysisFilters[analysisFilter.name].values = option.Id;
        analysisFilter._clearable = analysisFilter.values !== null;
      }
    }

    this.setAnalysisFilterSelectedOptions(analysisFilter);
    this.setAnalysisFilterValueLabel(analysisFilter);
    this.setAnalysisFiltersDisabled();
    this.getAnalyses();
    this.analysisFiltersSaveLocal();
  }

  private async analysisFilterClearSingle(analysFilter) {
    await this.analysisFilterClear(analysFilter, true);
    await this.getAnalyses();
  }

  private async analysisFilterClear(analysisFilter, useDefaults = false, batch = false) {
    if (!analysisFilter || typeof analysisFilter !== 'object') {
      return;
    }

    // Filter has custom clear-function
    if (analysisFilter.clear && !useDefaults) {
      analysisFilter.clear(analysisFilter.values);
    }

    // Use default clear-functions
    else {
      // Checkbox
      if (analysisFilter.type === this.analysisFilterTypes.CHECKBOX) {
        for (const [key, value] of Object.entries(analysisFilter.values)) {
          analysisFilter.values[key] = false;
        }
      }

      // Radio
      if (analysisFilter.type === this.analysisFilterTypes.RADIO) {
        analysisFilter.values = null;
      }

      // Radio simple
      if (analysisFilter.type === this.analysisFilterTypes.RADIO_SIMPLE) {
        analysisFilter.values = null;
      }

      // Range
      if (analysisFilter.type === this.analysisFilterTypes.RANGE) {
        analysisFilter.values.from = null;
        analysisFilter.values.to = null;
      }

      // Range Date
      if (analysisFilter.type === this.analysisFilterTypes.RANGE_DATE) {
        analysisFilter.values.from = null;
        analysisFilter.values.to = null;
      }
    }

    analysisFilter._clearable = false;

    this.setAnalysisFilterSelectedOptions(analysisFilter);
    this.setAnalysisFilterValueLabel(analysisFilter);

    if (!batch) {
      this.setAnalysisFiltersDisabled();
      this.analysisFiltersSaveLocal();
    }

    if (!useDefaults && !batch) {
      this.getAnalyses();
    }
  }

  private async clearAllAnalysisFilters() {
    for (const [key] of Object.entries(this.analysisFilters)) {
      await this.analysisFilterClear(this.analysisFilters[key], false, true);
    }
    this.setAnalysisFiltersDisabled();
    this.analysisFilters.searchText = null;
    this.analysisFilters.skip = 0;
    this.analysisFiltersSaveLocal();
    this.getAnalyses();
  }

  private analysisFilterGetSelectedOptions(analysisFilter) {
    return analysisFilter.options.filter((option) => {
      return analysisFilter.values[option.Id];
    });
  }

  private setAnalysisFilterInputFocus(analysisFilter, state: boolean) {
    setTimeout(() => {
      this.analysisFilterInputInFocus[analysisFilter.name] = state;
    });
  }

  private analysisFilterSearchKeydown(analysisFilter, event) {
    if (event.key == 'Escape' || event.key == 'Esc' || event.keyCode == 27) {
      analysisFilter._search = null;
    }
    return true;
  }

  private analysisFilterToggleVisible(analysisFilter, event) {
    analysisFilter._visible = event.target.checked;
    this.analysisFilterClear(analysisFilter); // Will also trigger netFiltersSaveLocal
  }

  private analysisFiltersSaveLocal() {
    const data: any = {};

    for (const [key, value] of Object.entries(this.analysisFilters)) {
      const filter = this.analysisFilters[key];

      if (!filter || typeof filter === 'undefined') {
        continue;
      } else if (typeof filter === 'string' || typeof filter === 'number') {
        data[key] = filter;
      } else {
        data[key] = {
          values: filter.values,
          visible: filter._visible ?? false,
        };
      }
    }

    localStorage.setItem(this.getKey(), JSON.stringify(data));
  }

  public setupAnalysisFilters() {
    return new Promise<void>(async (resolve, reject) => {
      interface AnalysisFilters {
        [key: string]: {
          /**
           * Name of the netfilter
           */
          name: string;

          /**
           * The label that is used in frontend.
           */
          label: string;

          /**
           * Type of netfilter. Should reference a constant from netFilterType.
           */
          type: string;

          /**
           * Options to use with the netfilters. Can be an array, async function or normal function. Must return array.
           * If using RADIO_SIMPLE-type, a string can also be returned.
           */
          options?: Array<any> | string;

          /**
           * Used to reference the options-function. Used to refresh options.
           */
          optionsFunc?: any;

          /**
           * Defines how this netfilter should be handled when creating query.
           * Accepts both `string` and `function`.
           *
           * If `string`: will use this field as key on query and will use netfilter's `values` as value.
           *
           * If `function`: the function must return an object containing `key` and `value`.
           */
          query?:
            | string
            | {
                (values?: object):
                  | {
                      key: string;
                      value: string;
                    }
                  | Array<{
                      key: string;
                      value: string;
                    }>;
              };

          /**
           * This fields contains all set values for the netfilter.
           */
          values?: string | object | Array<any> | boolean;

          /**
           * Sets default value for the netfilter.
           */
          defaultValues?: string | object | Array<any> | any;

          /**
           * Creates a custom clear-function instead if default. Does not return anything.
           */
          clear?: { (values?: object) };

          /**
           * Used to make filters conditional based on other filters or values.
           */
          disabled?: { (analysisFilters?: object) } | boolean | void | any;

          /**
           * Label to show when filter is disabled.
           */
          disabledLabel?: string;

          /**
           * Used as an array-version of values. Mostly used in the frontend.
           */
          _selectedOptions?: any;

          /**
           * Used to set a filter to disabled.
           */
          _disabled?: any;

          /**
           * Helper for frontend to keep dropdown open when an related element - i.e. the datepicker - is open.
           * The datepicker-element is not a child of the netfilter, and will use this variable to keep the state.
           */
          _childInFocus?: { from: boolean; to: boolean };

          /**
           * Used by localstorage and frontend to show/hide filters from list.
           */
          _visible?: boolean;

          /**
           * Used in view to show/hide the clear-button.
           */
          _clearable?: boolean;

          dropdownRef?: HTMLElement;
        };
      }

      /* 
        GET OPTIONS FOR FILTERS
      */
      const netShapeOptions: any = async () => {
        const data: any = await this.netShapeService.getAllCached().catch((err) => this.errorService.handleError(err));
        return data.filter((it: any) => !it.IsDeleted);
      };

      const floaterTypeOptions: any = async () => {
        const data: any = await this.floaterTypeService
          .getAllCached()
          .catch((err) => this.errorService.handleError(err));
        return data.filter((it: any) => !it.IsDeleted);
      };

      const designTypeOptions: any = async () => {
        const data: any = await this.designTypeService
          .getAllCached()
          .catch((err) => this.errorService.handleError(err));
        return data.filter((it: any) => !it.IsDeleted);
      };
      /*
        NETFILTERS
      */
      const analysisFilters: AnalysisFilters = {
        /* Omkrets */
        circumference: {
          name: 'circumference',
          label: this.i18n.tr('analysis.CircumferenceProduction'),
          type: this.analysisFilterTypes.RANGE,
        },
        /* Grunndesign */
        netShape: {
          name: 'netShape',
          label: this.i18n.tr('netdimension.designType'),
          type: this.analysisFilterTypes.CHECKBOX,
          options: await netShapeOptions(),
          query: 'netShapeIds',
        },
        /* Floater Types */
        floaterType: {
          name: 'floaterType',
          label: this.i18n.tr('notlogg.floaterType'),
          type: this.analysisFilterTypes.CHECKBOX,
          options: await floaterTypeOptions(),
          query: 'floaterTypeIds',
        },
        /* Typegodkjent Design */
        designType: {
          name: 'designType',
          label: this.i18n.tr('notlogg.designtype'),
          type: this.analysisFilterTypes.CHECKBOX,
          options: await designTypeOptions(),
          query: 'designTypeIds',
        },
        depthBottom: {
          name: 'depthBottom',
          label: this.i18n.tr('analysis.DepthBottom'),
          type: this.analysisFilterTypes.RANGE,
        },
        depthBottomCenter: {
          name: 'depthBottomCenter',
          label: this.i18n.tr('analysis.DepthBottomCenter'),
          type: this.analysisFilterTypes.RANGE,
        },
        depthBottomCenterExtraBottom: {
          name: 'depthBottomCenterExtraBottom',
          label: this.i18n.tr('analysis.DepthBottomCenterExtraBottom'),
          type: this.analysisFilterTypes.RANGE,
        },
        numberOfSides: {
          name: 'numberOfSides',
          label: this.i18n.tr('notlogg.NumberOfSides'),
          type: this.analysisFilterTypes.RANGE,
        },
        numberOfSideRopes: {
          name: 'numberOfSideRopes',
          label: this.i18n.tr('notlogg.NumberOfSideRopes'),
          type: this.analysisFilterTypes.RANGE,
        },
        numberOfLiftingRopes: {
          name: 'numberOfLiftingRopes',
          label: this.i18n.tr('notlogg.NumberOfLiftingRopes'),
          type: this.analysisFilterTypes.RANGE,
        },
        numberOfCrossRopes: {
          name: 'numberOfCrossRopes',
          label: this.i18n.tr('notlogg.NumberOfCrossRopes'),
          type: this.analysisFilterTypes.RANGE,
        },
        isCombiNet: {
          name: 'isCombiNet',
          label: this.i18n.tr('general.combiNet'),
          type: this.analysisFilterTypes.RADIO_SIMPLE,
          options: [
            { label: this.i18n.tr('general.showAll'), value: null },
            { label: this.i18n.tr('general.yes'), value: true },
            { label: this.i18n.tr('general.no'), value: false },
          ],
        },
      };

      // Extra filters that is only available some places
      if (this.context === 'special-product-list' || this.context === 'special-product-list-customer-list') {
        const specialProductTypeOptions: any = async () => {
          const data: any = await this.specialProductTypeService
            .getAllCached()
            .catch((err) => this.errorService.handleError(err));
          return data.filter((x) => !x.IsDeleted).sort((a, b) => (a.SortIndex > b.SortIndex ? 1 : -1));
        };

        /* Type spesialprodukt */
        analysisFilters.specialProductType = {
          name: 'specialProductType',
          label: this.i18n.tr('notlogg.specialproducttype'),
          type: this.analysisFilterTypes.CHECKBOX,
          options: await specialProductTypeOptions(),
          query: 'specialProductTypeIds',
        };

        /* Lengde */
        analysisFilters.specialProductLength = {
          name: 'specialProductLength',
          label: this.i18n.tr('specialProduct.Length'),
          type: this.analysisFilterTypes.RANGE,
        };

        /* Bredde */
        analysisFilters.specialProductWidth = {
          name: 'specialProductWidth',
          label: this.i18n.tr('specialProduct.Width'),
          type: this.analysisFilterTypes.RANGE,
        };

        // Remove filters from list
        delete analysisFilters.meshsize;
        delete analysisFilters.circumference;
        delete analysisFilters.depth;
        delete analysisFilters.netType;
      }

      // Function for refreshing options-list
      // Used with filter-disabling
      this.eventAggregator.subscribe('analysisFilterOptionsRefresh', async (analysisFilterKey) => {
        this.analysisFilters[analysisFilterKey].options = await analysisFilters[analysisFilterKey].optionsFunc();
        this.setAnalysisFilterValueLabel(this.analysisFilters[analysisFilterKey]);
      });

      // Functions and stuff to do when loading filters

      const analysisFiltersListLocal = JSON.parse(localStorage.getItem(this.getKey()));

      // Default filters visible
      // (default values are set directly in filters, `defaultValues`)
      if (!analysisFiltersListLocal) {
        // Default
        analysisFilters.circumference._visible = true;
        analysisFilters.netShape._visible = true;
        analysisFilters.floaterType._visible = true;
        analysisFilters.designType._visible = true;
        analysisFilters.depthBottom._visible = true;
        analysisFilters.depthBottomCenter._visible = true;
        analysisFilters.numberOfSides._visible = true;
        analysisFilters.numberOfSideRopes._visible = true;
      }

      for (const [key, filter] of Object.entries(analysisFilters)) {
        // Set default values and helpers for filters
        if (!filter.values) {
          if (analysisFilters[key].type === this.analysisFilterTypes.CHECKBOX) {
            analysisFilters[key].values = {};
            analysisFilters[key]._selectedOptions = [];
          }

          if (analysisFilters[key].type === this.analysisFilterTypes.RADIO) {
            analysisFilters[key].values = null;
          }

          if (analysisFilters[key].type === this.analysisFilterTypes.RANGE) {
            analysisFilters[key].values = { from: null, to: null };
          }

          if (analysisFilters[key].type === this.analysisFilterTypes.RANGE_DATE) {
            analysisFilters[key].values = { from: null, to: null };
            analysisFilters[key]._childInFocus = { from: false, to: false };
          }

          if (analysisFilters[key].type === this.analysisFilterTypes.RADIO_SIMPLE) {
            analysisFilters[key].values = null;

            // Helper for RADIO_SIMPLE-filter, so we don't have to write
            // options for show and hide all the times
            if (typeof analysisFilters[key].options === 'string') {
              analysisFilters[key].options = [
                { label: this.i18n.tr('general.show'), value: null },
                { label: this.i18n.tr('general.hide'), value: false },
                { label: analysisFilters[key].options, value: true },
              ];
            }
          }
        }

        // Set values to default values, if any
        if (analysisFilters[key].defaultValues) {
          if (typeof analysisFilters[key].defaultValues === 'function') {
            analysisFilters[key].values = await analysisFilters[key].defaultValues();
          } else {
            analysisFilters[key].values = analysisFilters[key].defaultValues;
          }
        }

        const analysisFiltersListLocal = JSON.parse(localStorage.getItem(this.getKey()));

        // Handling localstorage - sets values and visible-state
        if (analysisFiltersListLocal) {
          if (analysisFiltersListLocal[key]) {
            analysisFilters[key]._visible =
              analysisFiltersListLocal[key].visible === undefined ? true : analysisFiltersListLocal[key].visible;
            analysisFilters[key].values = analysisFiltersListLocal[key].values;
          }
        }

        // Show/hide clear-button
        if (analysisFilters[key].type === this.analysisFilterTypes.CHECKBOX) {
          analysisFilters[key]._clearable = Object.values(analysisFilters[key].values).includes(true);

          if (analysisFilters[key].defaultValues && typeof analysisFilters[key] === 'object') {
            const isDefault = Object.entries(analysisFilters[key].values).every(
              ([valueKey, valueValue]) => !valueValue || analysisFilters[key].defaultValues[valueKey] == valueValue
            );
            if (isDefault) {
              analysisFilters[key]._clearable = false;
            }
          }
        }

        if (analysisFilters[key].type === this.analysisFilterTypes.RANGE) {
          analysisFilters[key]._clearable = analysisFilters[key].values['from'] || analysisFilters[key].values['to'];
        }

        if (analysisFilters[key].type === this.analysisFilterTypes.RANGE_DATE) {
          analysisFilters[key]._clearable = analysisFilters[key].values['from'] || analysisFilters[key].values['to'];
        }

        if (analysisFilters[key].type === this.analysisFilterTypes.RADIO_SIMPLE) {
          analysisFilters[key]._clearable = analysisFilters[key].values !== null;
        }

        if (analysisFilters[key].type === this.analysisFilterTypes.RADIO) {
          analysisFilters[key]._clearable = analysisFilters[key].values !== null;
        }

        // Set selected options (works for checkboxes)
        this.setAnalysisFilterSelectedOptions(analysisFilters[key]);

        // Sets value-label
        this.setAnalysisFilterValueLabel(analysisFilters[key]);
      }

      // Some final overrides for contexts
      if (this.context === 'customer-list' || this.context === 'special-product-list-customer-list') {
        analysisFilters.customerIds._visible = false;
      }

      this.analysisFilters = analysisFilters;

      return resolve();
    });
  }

  private analysisFilterMouseEnter(analysisFilter) {
    this.setAnalysisFilterInputFocus(analysisFilter, true);

    if (analysisFilter.type === this.analysisFilterTypes.RANGE_DATE && analysisFilter._dropdownRef) {
      const left = window.scrollX + analysisFilter._dropdownRef.getBoundingClientRect().left;
      const width = 477; // Width of datepicker-filter
      const isOverflowing = left + width > window.innerWidth ? true : false;
      if (isOverflowing) {
        analysisFilter._dropdownRef.classList.add('servicefilter-dropdown--datefilter-overflow-fix');
      } else {
        analysisFilter._dropdownRef.classList.remove('servicefilter-dropdown--datefilter-overflow-fix');
      }
    }
  }
}
